<template>
  <div class="delivery_address">
    <div class="address_num">
      <div>
        <h3>已经保存的收货地址</h3>
        <p>您已创建 <span>0</span> 个收货地址，最多可创建 <span>20</span> 个</p>
      </div>
      <button>新增收货地址</button>
    </div>
    <table width="100%" v-if="false">
      <tr>
        <th width="234px">收货单位</th>
        <th width="240px">收货地址</th>
        <th width="120px">收货联系人</th>
        <th width="144px">联系人手机号码</th>
        <th>操作</th>
      </tr>
      <tr v-for="(list, index) in address" :key="index">
        <td>{{ list.consignee | consignee }}</td>
        <td
          :title="list.province + list.city + list.county + list.detailAddress"
        >
          <span class="address"
            >{{ list.province }}{{ list.city }}{{ list.county
            }}{{ list.detailAddress }}</span
          >
        </td>
        <td>{{ list.nameAddress }}</td>
        <td>{{ list.phoneAddress }}</td>
        <td>
          <p v-if="list.defaultAddress">默认收货地址</p>
          <a v-else style="margin-left: 0" @click="SetDefault(list.id)"
            >设为默认收货地址</a
          >
          <a @click="copyreader(list)">编辑</a>
          <a @click="deleteAddress(list.id)">删除</a>
        </td>
      </tr>
    </table>
    <div class="add_address" v-else>
      <div class="title">新增收货地址</div>
      <div class="content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="auto"
          class="demo-ruleForm"
          label-position="left"
        >
          <el-form-item label="收货人" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="收货人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="num">
            <el-input v-model="ruleForm.num" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="收货时间" prop="time">
            <el-select v-model="ruleForm.time">
              <el-option
                label="周一至周日均可收货"
                value="周一至周日均可收货"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收货地址" prop="ShippingAddress">
            <el-cascader
              size="large"
              :options="options"
              v-model="ruleForm.ShippingAddress"
              @change="handleChange"
            >
            </el-cascader>
            <!-- <el-input v-model="ruleForm.ShippingAddress"></el-input> -->
          </el-form-item>
          <el-form-item label="详细地址" prop="DetailedAddress">
            <el-input
              v-model="ruleForm.DetailedAddress"
              placeholder="详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址类型" prop="type">
            <el-radio-group v-model="ruleForm.type">
              <el-radio label="家庭"></el-radio>
              <el-radio label="公司"></el-radio>
              <el-radio label="其他"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";

export default {
  name: "delivery-address",

  data() {
    return {
      address: [], //用户地址
      newAddress: false, //新增地址显示隐藏
      FormState: 0,
      ruleForm: {
        ReceivingUnit: "", //收货单位
        ShippingAddress: "", //所在地区
        DetailedAddress: "", //详情地址
        name: "", //名字
        num: "", //手机号
        default: "", //是否默认地址
        addressCode: "", //地区号
        time: "周一至周日均可收货",
        type: "家庭", //类型
      },
      options: regionData, //城市信息
      selectedOptions: "", //选中的城市信息

      rules: {
        name: [
          { required: true, message: "请填写收货联系人", trigger: "blur" },
          { max: 6, message: "最多为六个字符", trigger: "blur" },
        ],
        num: [
          { required: true, message: "请填写联系人手机号码", trigger: "blur" },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (/^(?:(?:\+|00)86)?1\d{10}$/.test(value) == false) {
                callback(new Error("手机号格式不正确"));
              } else {
                callback();
              }
            },
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        time: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        ShippingAddress: [
          { required: true, message: "请选择所在地区", trigger: "blur" },
        ],
        DetailedAddress: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
    };
  },

  created() {
    this.getAddress();
  },

  methods: {
    //查询地址
    async getAddress() {
      let address = JSON.parse(sessionStorage.getItem("userAddress"));
      if (address) {
        this.address = address;
      } else {
        const data = await this.$API.personalUser.queryAddress({
          object: {
            pageNum: 1,
            pageSize: 1,
          },
        });
        this.address = data.data.data;
        sessionStorage.setItem("userAddress", JSON.stringify(this.address));
      }
    },
    //修改地址接口
    async updateAddress() {
      const data = await this.$API.personalUser
        .updateAddress({
          object: {
            addressCode: this.ruleForm.addressCode,
            province: this.selectedOptions[0],
            city: this.selectedOptions[1],
            county: this.selectedOptions[2] ? this.selectedOptions[2] : "",
            consignee: this.ruleForm.ReceivingUnit,
            country: "",
            defaultAddress: this.ruleForm.default,
            detailAddress: this.ruleForm.DetailedAddress,
            id: this.ruleForm.id,
            nameAddress: this.ruleForm.name,
            phoneAddress: this.ruleForm.num,
          },
        })
        .then((data) => {
          this.$message({
            message: "更改成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("更改失败");
        });
      sessionStorage.clear("userAddress");
      this.getAddress();
    },
    //设置默认地址
    async SetDefault(id) {
      this.$confirm("您确定要设置当前地址为默认地址吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .updateAddress({
              object: {
                defaultAddress: true,
                id: id,
              },
            })
            .then((data) => {
              this.$message({
                message: "设置成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("设置失败");
            });

          sessionStorage.clear("userAddress");
          this.getAddress();
        })
        .catch(() => {
          this.$message({
            message: "取消成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        });
    },
    //删除收货地址
    async deleteAddress(id) {
      this.$confirm("您确定要删除选中地址吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .deleteAddress({
              object: {
                id: id,
              },
            })
            .then((data) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("删除失败");
            });
          sessionStorage.clear("userAddress");
          this.getAddress();
        })
        .catch(() => {
          this.$message({
            message: "取消成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        });
    },
    //编辑
    copyreader(Default) {
      this.FormState = 1;
      let Code = Default.addressCode.split(",");
      console.log(Code);

      this.ruleForm = {
        addressCode: Default.addressCode,
        ReceivingUnit: Default.consignee, //收货单位
        ShippingAddress: Code, //所在地区
        DetailedAddress: Default.detailAddress, //详情地址
        name: Default.nameAddress, //名字
        num: Default.phoneAddress, //手机号
        default: Default.defaultAddress, //是否默认地址
        id: Default.id,
        province: Default.province,
        city: Default.city,
        county: Default.county,
      };
      this.newAddress = true;
    },
    handleChange() {
      var loc = [];
      let addressCode = "";
      for (let i = 0; i < this.ruleForm.ShippingAddress.length; i++) {
        loc.push(CodeToText[this.ruleForm.ShippingAddress[i]]);
        addressCode += this.ruleForm.ShippingAddress[i] + ",";
      }
      this.ruleForm.addressCode = addressCode.slice(0, addressCode.length - 1);
      this.selectedOptions = loc;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.FormState === 0) {
            this.insertAddress();
            this.closeForm();
          } else {
            this.updateAddress();
            this.closeForm();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery_address {
  .address_num {
    margin-bottom: 20px;
    display: flex;
    padding: 12px 20px;
    justify-content: space-between;
    background-color: #fff;
    div {
      display: flex;
      align-items: center;
      h3 {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      p {
        margin-left: 12px;
        font-size: 12px;
        color: #888888;
        span {
          color: var(--subjectColor);
        }
      }
    }
    button {
      height: 32px;
      padding: 0 14px;
      border-radius: 2px;
      border: 1px solid var(--subjectColor);
      color: var(--subjectColor);
      background-color: #fff;
      cursor: pointer;
      font-size: 12px;
    }
  }
  table {
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #ddecfb;
    tr:nth-child(1) {
      height: 40px;
      background: #f3f8fe;
    }
    tr {
      th:nth-child(1),
      td:nth-child(1) {
        padding-left: 30px;
      }
      th:last-child {
        padding-right: 30px;
        text-align: center;
      }
      th {
        text-align: start;
        font-weight: bold;
        padding-right: 60px;
      }
      td {
        padding: 14px 0;
        padding-right: 60px;
      }
      .address {
        white-space: normal;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      td:last-child {
        display: flex;
        height: 60px;
        width: 100%;
        align-items: center;
        padding-right: 30px;

        justify-content: flex-end;
        a {
          margin-left: 18px;
          color: #086ac2;
          cursor: pointer;
        }
        a:hover {
          color: var(--subjectColor);
        }
      }
    }
  }
  .add_address {
    background-color: #fff;
    .title {
      padding: 12px 20px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
      border-bottom: 1px solid #eee;
    }
    .content {
      padding: 30px 20px;
      ::v-deep .el-form {
        .el-form-item {
          margin-bottom: 16px;
          .el-form-item__label-wrap {
            // width: 83px;
            margin-left: 0px !important;
            padding-right: 20px;
            .el-form-item__label {
              font-size: 14px;
              color: #666666;
            }
            .el-form-item__label::before {
              margin-right: 0;
            }
          }
          .el-form-item__content {
            margin-left: 99px !important;
            input {
              width: 300px;
              height: 36px;
              border-radius: 2px;
              // border-color: #dddddd;
              padding: 0 10px;
            }
            .el-form-item__error {
              padding-top: 2px;
            }
          }
        }
        .el-button {
          width: 120px;
          height: 36px;
          border-radius: 2px;
          padding: 0;
        }
      }
    }
  }
}
</style>